import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import * as XLSX from 'xlsx';

/**
 * @class AsyncExcel
 */
class AsyncExcel extends Component {
  /**
   * @constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  downloadReport = () => {
    const data = this.props.toExport;
    const headers = this.props.headers.map(header => header.label);
    const rows = data.map(row => this.props.headers.map(header => row[header.key]));

    // Combine headers and rows for the Excel file
    const sheetData = [headers, ...rows];

    // Create a workbook and a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Generate and download the Excel file
    XLSX.writeFile(workbook, "report.xlsx");
  }

  /**
   * Render the button to export data in Excel
   * @return {HTML} render excel
   */
  render() {
    return (
      <>
        <Button
          className="btn btn-round btn-info mr-1"
          id="export"
          onClick={this.downloadReport}
        >
          {this.props.sentence}
        </Button>
      </>
    );
  }
}

export default AsyncExcel;
